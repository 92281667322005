import React from 'react';
import { Link } from 'react-router-dom';

function Wifity() {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Wifity</h1>
      <p style={styles.paragraph}>
        O <strong>Wifity</strong> é um aplicativo projetado para analisar o tráfego da rede, fornecendo informações relevantes e detalhadas sobre o uso da sua conexão. Essas informações são apresentadas de forma clara para que os usuários possam utilizá-las de maneira consciente e responsável.
      </p>
      <p style={styles.paragraph}>
        Com o Wifity, você pode monitorar sua rede, entender melhor o tráfego e tomar decisões informadas sobre o uso da sua conexão. O objetivo é capacitar os usuários com conhecimento sobre o desempenho da rede e possíveis pontos de melhoria.
      </p>
      <p style={styles.paragraph}>
        Para mais informações, consulte nossa <Link to="/wifity/policy">Política de Privacidade do Wifity</Link>.
      </p>
    </div>
  );
}

const styles = {
  container: {
    textAlign: 'justify',
    margin: '20px',
    textIndent: '30px',
    maxWidth: '100%', // Responsivo
    boxSizing: 'border-box',
  },
  title: {
    fontSize: '2rem',
    textAlign: 'center',
  },
  paragraph: {
    marginBottom: '1rem',
    lineHeight: '1.6',
    fontSize: '1rem',
    '@media (max-width: 768px)': {
      fontSize: '0.9rem',
      textIndent: '15px',
    },
  },
};

export default Wifity;
