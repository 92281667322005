import React from 'react';

function BlockityPolicy() {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Política de Privacidade do Blockity</h1>
      
      <p style={styles.paragraph}><strong>1. Introdução</strong></p>
      <p style={styles.paragraph}>
        Bem-vindo ao Blockity! Este aplicativo foi desenvolvido para ajudar você a gerenciar e bloquear chamadas indesejadas de maneira eficaz. Estamos comprometidos em proteger a sua privacidade e garantir que seus dados sejam tratados de forma segura e responsável.
      </p>

      <p style={styles.paragraph}><strong>2. Coleta de Informações</strong></p>
      <p style={styles.paragraph}>
        O Blockity solicita acesso à lista de contatos e às chamadas recentes do seu dispositivo. Essas informações são utilizadas exclusivamente para permitir que o aplicativo identifique e bloqueie chamadas indesejadas de forma eficiente. O Blockity utiliza essa informação de forma responsável, sem acessar outros dados do usuário.
      </p>

      <p style={styles.paragraph}><strong>3. Uso de Informações</strong></p>
      <p style={styles.paragraph}>
        As informações de contatos e chamadas recentes são utilizadas unicamente para a funcionalidade central do Blockity, que é o bloqueio de chamadas indesejadas. O Blockity não armazena, não comercializa, não divulga dados que não são objeto do software.
      </p>

      <p style={styles.paragraph}><strong>4. Permissões do Aplicativo</strong></p>
      <p style={styles.paragraph}>
        O Blockity pode solicitar permissões para acessar a lista de contatos e as chamadas recentes. Essas permissões são necessárias para o correto funcionamento do aplicativo e são utilizadas exclusivamente para a finalidade de bloqueio de chamadas.
      </p>

      <p style={styles.paragraph}><strong>5. Compartilhamento de Informações</strong></p>
      <p style={styles.paragraph}>
        O Blockity não compartilha, comercializa ou divulga as informações coletadas. Todos os dados utilizados pelo aplicativo são processados localmente no dispositivo do usuário, garantindo que sua privacidade seja respeitada.
      </p>

      <p style={styles.paragraph}><strong>6. Segurança</strong></p>
      <p style={styles.paragraph}>
        Embora o Blockity utilize informações sensíveis como contatos e chamadas recentes, aplicamos as melhores práticas de segurança para garantir que essas informações sejam tratadas com o máximo de cuidado e proteção.
      </p>

      <p style={styles.paragraph}><strong>7. Atualizações</strong></p>
      <p style={styles.paragraph}>
        Esta política de privacidade pode ser atualizada periodicamente para refletir mudanças nas práticas do aplicativo ou em resposta a novas exigências legais. A data da última atualização será revisada e qualquer mudança significativa será comunicada através da loja de aplicativos.
      </p>

      <p style={styles.paragraph}><strong>8. Contato</strong></p>
      <p style={styles.paragraph}>
        Se você tiver qualquer dúvida sobre esta política de privacidade ou sobre o uso do Blockity, entre em contato conosco através do suporte disponível na loja de aplicativos.
      </p>

      <p style={styles.paragraph}><strong>9. Conformidade com a loja de aplicativos</strong></p>
      <p style={styles.paragraph}>
        O Blockity está em conformidade com todas as diretrizes da loja de aplicativos, garantindo que o aplicativo respeita e protege os direitos dos usuários, conforme as exigências da plataforma.
      </p>
    </div>
  );
}

const styles = {
  container: {
    textAlign: 'justify',
    margin: '20px',
    textIndent: '30px',
    maxWidth: '100%', // Responsivo
    boxSizing: 'border-box',
  },
  title: {
    fontSize: '2rem',
    textAlign: 'center',
  },
  paragraph: {
    marginBottom: '1rem',
    lineHeight: '1.6',
    fontSize: '1rem',
    '@media (max-width: 768px)': {
      fontSize: '0.9rem',
      textIndent: '15px',
    },
  },
};

export default BlockityPolicy;
