import React from 'react';
import { Link } from 'react-router-dom';

function Terapity() {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Terapity</h1>
      <p style={styles.paragraph}>
        O <strong>Terapity</strong> foi inspirado por uma pessoa muito especial que, desde cedo, apresentou características de atraso no desenvolvimento. A intervenção rápida ainda na primeira infância contribuiu significativamente para a melhora no desenvolvimento. As terapias, os profissionais envolvidos e todo o suporte foram fundamentais nesse processo.
      </p>
      <p style={styles.paragraph}>
        No entanto, como é comum, o tempo de saturação das atividades é muito baixo, e rapidamente as atividades se tornam obsoletas. O Terapity foi pensado para aumentar o tempo de engajamento nas atividades, tornando-as mais atrativas, inovando e reinventando o conceito de personalização e smartizando as atividades.
      </p>
      <p style={styles.paragraph}>
        Dessa forma, o Terapity busca atender todas as pessoas que precisam de um suporte adicional, além de servir como uma ferramenta de distração para todos. É importante lembrar que o acompanhamento de um profissional é indispensável em determinados casos.
      </p>
      <p style={styles.paragraph}>
        O Terapity é totalmente gratuito. Os anúncios exibidos são apenas uma forma de cobrir os custos operacionais e não geram nenhum custo para os usuários.
      </p>
      <p style={styles.paragraph}>
        Para mais informações, consulte nossa <Link to="/terapity/policy">Política de Privacidade do Terapity</Link>.
      </p>
    </div>
  );
}

const styles = {
  container: {
    textAlign: 'justify',
    margin: '20px',
    textIndent: '30px',
    maxWidth: '100%', // Responsivo
    boxSizing: 'border-box',
  },
  title: {
    fontSize: '2rem',
    textAlign: 'center',
  },
  paragraph: {
    marginBottom: '1rem',
    lineHeight: '1.6',
    fontSize: '1rem',
    '@media (max-width: 768px)': {
      fontSize: '0.9rem',
      textIndent: '15px',
    },
  },
};

export default Terapity;
