import React from 'react';
import { Link } from 'react-router-dom';

function Blockity() {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Blockity</h1>
      <p style={styles.paragraph}>
        O <strong>Blockity</strong> foi desenvolvido em resposta ao crescente descontentamento causado por diversas chamadas recebidas de forma indevida. Pensando no bem-estar dos usuários e na necessidade de se proteger contra essa invasão de privacidade, criamos o Blockity para ajudar a diminuir a grande quantidade de chamadas que causam desconforto.
      </p>
      <p style={styles.paragraph}>
        Com o Blockity, você pode filtrar e bloquear chamadas indesejadas, proporcionando mais tranquilidade e controle sobre suas comunicações.
      </p>
      <p style={styles.paragraph}>
        Para mais informações, consulte nossa <Link to="/blockity/policy">Política de Privacidade do Blockity</Link>.
      </p>
    </div>
  );
}

const styles = {
  container: {
    textAlign: 'justify',
    margin: '20px',
    textIndent: '30px',
    maxWidth: '100%', // Responsivo
    boxSizing: 'border-box',
  },
  title: {
    fontSize: '2rem',
    textAlign: 'center',
  },
  paragraph: {
    marginBottom: '1rem',
    lineHeight: '1.6',
    fontSize: '1rem',
    '@media (max-width: 768px)': {
      fontSize: '0.9rem',
      textIndent: '15px',
    },
  },
};

export default Blockity;

