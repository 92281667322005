import React from 'react';
import { Link } from 'react-router-dom';

function Consity() {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Consity</h1>
      <p style={styles.paragraph}>
        O <strong>Consity</strong> foi desenvolvido para atender as demandas dos profissionais e estabelecimentos que necessitam smartizar a parte administrativa. Nosso objetivo é facilitar o dia a dia desses profissionais, proporcionando soluções eficientes que tornam as tarefas administrativas mais simples e rápidas.
      </p>
      <p style={styles.paragraph}>
        O Consity é a ferramenta ideal para quem busca automatizar e otimizar a gestão administrativa, garantindo mais tempo e foco nas atividades essenciais.
      </p>
      <p style={styles.paragraph}>
        Para mais informações, consulte nossa <Link to="/consity/policy">Política de Privacidade do Consity</Link>.
      </p>
    </div>
  );
}

const styles = {
  container: {
    textAlign: 'justify',
    margin: '20px',
    textIndent: '30px',
    maxWidth: '100%', // Responsivo
    boxSizing: 'border-box',
  },
  title: {
    fontSize: '2rem',
    textAlign: 'center',
  },
  paragraph: {
    marginBottom: '1rem',
    lineHeight: '1.6',
    fontSize: '1rem',
    '@media (max-width: 768px)': {
      fontSize: '0.9rem',
      textIndent: '15px',
    },
  },
};

export default Consity;
