import React from 'react';

function Footer() {
  return (
    <footer>
     <span className="copyright">&copy; Trinity </span>
    </footer>
  );
}

export default Footer;
