import React from 'react';
import { Link } from 'react-router-dom';

function Portrity() {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Portrity</h1>
      <p style={styles.paragraph}>
        O <strong>Portrity</strong> é um porta-retratos digital que transforma o ambiente, oferecendo diversas possibilidades de estilos de transição de fotos, como duração, tamanho, velocidade e estilo. Mais do que apenas exibir fotos, o Portrity torna o ambiente mais agradável e moderno, funcionando também como um objeto de decoração.
      </p>
      <p style={styles.paragraph}>
        Com o Portrity, você pode personalizar a exibição das suas memórias de uma maneira que se adapta ao seu estilo e preferências, tornando cada espaço mais acolhedor e único.
      </p>
      <p style={styles.paragraph}>
        Para mais informações, consulte nossa <Link to="/portrity/policy">Política de Privacidade do Portrity</Link>.
      </p>
    </div>
  );
}

const styles = {
  container: {
    textAlign: 'justify',
    margin: '20px',
    textIndent: '30px',
    maxWidth: '100%', // Responsivo
    boxSizing: 'border-box',
  },
  title: {
    fontSize: '2rem',
    textAlign: 'center',
  },
  paragraph: {
    marginBottom: '1rem',
    lineHeight: '1.6',
    fontSize: '1rem',
    '@media (max-width: 768px)': {
      fontSize: '0.9rem',
      textIndent: '15px',
    },
  },
};

export default Portrity;
