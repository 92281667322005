import React from 'react';
import { Link } from 'react-router-dom';

function Potity() {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Potity</h1>
      <p style={styles.paragraph}>
        O <strong>Potity</strong> foi desenvolvido com base no conceito de honey pot, uma estratégia utilizada para criar armadilhas que distraem e enganam invasores indesejados. Através dessa técnica, o Potity ajuda a proteger sistemas e informações ao atrair potenciais ameaças para um ambiente controlado.
      </p>
      <p style={styles.paragraph}>
        Com o Potity, você pode implementar medidas de segurança que não apenas detectam, mas também desviam invasores, garantindo que suas operações principais permaneçam seguras e ininterruptas.
      </p>
      <p style={styles.paragraph}>
        Para mais informações, consulte nossa <Link to="/potity/policy">Política de Privacidade do Potity</Link>.
      </p>
    </div>
  );
}

const styles = {
  container: {
    textAlign: 'justify',
    margin: '20px',
    textIndent: '30px',
    maxWidth: '100%', // Responsivo
    boxSizing: 'border-box',
  },
  title: {
    fontSize: '2rem',
    textAlign: 'center',
  },
  paragraph: {
    marginBottom: '1rem',
    lineHeight: '1.6',
    fontSize: '1rem',
    '@media (max-width: 768px)': {
      fontSize: '0.9rem',
      textIndent: '15px',
    },
  },
};

export default Potity;
