import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ContactForm from './components/ContactForm';
import './App.css';
import logo from './assets/3nity.png';
import arrow from './assets/up-arrow.png';
import whatsappLogo from './assets/whatsapp.png';
import website from './assets/website.png';
import mobile from './assets/mobile.png';
import lucro from './assets/lucro.png';
import tecnologia from './assets/tecnologia.png';
import contato from './assets/contato.png';
import pomity from './assets/pomity.png';
import blockity from './assets/blockity.png';
import terapity from './assets/terapity.png';
import potity from './assets/potity.png';
import portrity from './assets/portrity.png';
import consity from './assets/consity.png';
import wifity from './assets/wifity.png';  // Adicionando imagem do Wifity

function App() {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 200) { 
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 200) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [checkScrollTop, showScroll]);  // Adicionando checkScrollTop como dependência

  return (
    <div className="App">
      <header className="App-header">
        <h1 className="App-title">3nity</h1>
        <h2 className="App-subtitle">Criação de aplicativos mobile</h2>
      </header>
      <Navbar />
      <main>
        <section id="sobre" className="section">
          <h2 className="section-title">Sobre</h2>
          <div className="section-content">
            <img src={website} alt="Website" className="section-image" />
            <p className="section-text">A 3nity é uma empresa especializada na criação de sites, aplicativos mobile e automação que visa oferecer a melhor solução para o seu objetivo.</p>
            <img src={mobile} alt="Mobile" className="section-image" />
          </div>
          
        </section>
        <div className="divider"></div>
        <section id="tecnologia" className="section">
          <h2 className="section-title">Tecnologia</h2>
          <div className="section-content single-image">
            <img src={tecnologia} alt="Tecnologia" className="section-image" />
            <p className="section-text">A 3nity utiliza as tecnologias mais atuais do mercado.</p>
          </div>
        </section>
        <div className="divider"></div>
        <section id="modelos" className="section">
          <h2 className="section-title">Produtos</h2>
          <p className="section-text">Aplicativos desenvolvidos.</p>
          <div className="modelos-content">
            <a href="/pomity">
              <img src={pomity} alt="Pomity" className="model-image" />
              <p className="model-name">Pomity</p>
            </a>
            <a href="/blockity">
              <img src={blockity} alt="Blockity" className="model-image" />
              <p className="model-name">Blockity</p>
            </a>
            <a href="/terapity">
              <img src={terapity} alt="Terapity" className="model-image" />
              <p className="model-name">Terapity</p>
            </a>
            <a href="/potity">
              <img src={potity} alt="Potity" className="model-image" />
              <p className="model-name">Potity</p>
            </a>
            <a href="/portrity">
              <img src={portrity} alt="Portrity" className="model-image" />
              <p className="model-name">Portrity</p>
            </a>
            <a href="/consity">
              <img src={consity} alt="Consity" className="model-image" />
              <p className="model-name">Consity</p>
            </a>
            <a href="/wifity">
              <img src={wifity} alt="Wifity" className="model-image" />
              <p className="model-name">Wifity</p>
            </a>
          </div>
        </section>
        <div className="divider"></div>
        <section id="contato" className="section">
          <h2 className="section-title">Contato</h2>
          <div className="section-content single-image">
            <img src={contato} alt="Contato" className="section-image" />
          </div>
          <ContactForm />
        </section>
      </main>
      <img src={logo} className="App-logo rotating-logo" alt="logo" />
      <Footer />
      <img 
        src={arrow} 
        className="scroll-top" 
        onClick={scrollTop} 
        style={{ display: showScroll ? 'block' : 'none' }} 
        alt="Scroll to top" 
      />
      <a 
        href="https://wa.me/5521967475789" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <img src={whatsappLogo} className="whatsapp-float" alt="WhatsApp" />
      </a>
    </div>
  );
}

export default App;
