import React from 'react';
import { Link } from 'react-router-dom';

function Pomity() {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Pomity</h1>
      <p style={styles.paragraph}>
        O <strong>Pomity</strong> foi criado em homenagem a uma pessoa muito especial, que apresentava dificuldades em se concentrar para estudar. Inspirados por essa pessoa e pelas inúmeras outras que enfrentam o mesmo desafio, desenvolvemos o Pomity para oferecer uma solução prática e eficaz.
      </p>
      <p style={styles.paragraph}>
        Baseado na técnica Pomodoro, o Pomity ajuda a manter o foco e a produtividade, dividindo o tempo de estudo em intervalos gerenciáveis, intercalados por breves pausas. Nosso objetivo é ajudar todos aqueles que precisam de uma mãozinha extra para se concentrar e alcançar seus objetivos acadêmicos ou profissionais.
      </p>
      <p style={styles.paragraph}>
        Para mais informações, consulte nossa <Link to="/pomity/policy">Política de Privacidade do Pomity</Link>.
      </p>
    </div>
  );
}

const styles = {
  container: {
    textAlign: 'justify',
    margin: '20px',
    textIndent: '30px',
    maxWidth: '100%', // Responsivo
    boxSizing: 'border-box',
  },
  title: {
    fontSize: '2rem',
    textAlign: 'center',
  },
  paragraph: {
    marginBottom: '1rem',
    lineHeight: '1.6',
    fontSize: '1rem',
    '@media (max-width: 768px)': {
      fontSize: '0.9rem',
      textIndent: '15px',
    },
  },
};

export default Pomity;
