import React, { useState } from 'react';

function ContactForm() {
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    window.location.href = `mailto:contato@3nity.com.br?body=${encodeURIComponent(message)}`;
  };

  return (
    <form 
      onSubmit={handleSubmit} 
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '20px auto',
        width: '80%',
        maxWidth: '600px',
      }}
    >
      <label 
        htmlFor="message" 
        style={{
          marginBottom: '10px',
          fontSize: '1.2rem',
          color: '#4B0082',
          textAlign: 'left',
          width: '100%',
        }}
      >
        Envie sua mensagem:
      </label>
      <textarea
        id="message"
        name="message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        required
        style={{
          width: '100%',
          height: '150px',
          padding: '10px',
          marginBottom: '20px',
          border: '1px solid #ccc',
          borderRadius: '4px',
          fontSize: '1rem',
        }}
      />
      <button 
        type="submit" 
        style={{
          backgroundColor: '#00FFFF',
          color: '#4B0082',
          padding: '10px 20px',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          fontSize: '1rem',
          textAlign: 'center',
        }}
      >
        Enviar
      </button>
    </form>
  );
}

export default ContactForm;
