import React from 'react';

function PomityPolicy() {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Política de Privacidade do Pomity</h1>
      <p style={styles.paragraph}><strong>1. Introdução</strong></p>
      <p style={styles.paragraph}>
        Bem-vindo ao Pomity, uma aplicação desenvolvida para ajudar você a melhorar sua concentração e produtividade utilizando a técnica Pomodoro. Estamos comprometidos em proteger a sua privacidade e garantir que seus dados sejam tratados de forma segura e responsável.
      </p>

      <p style={styles.paragraph}><strong>2. Coleta de Informações</strong></p>
      <p style={styles.paragraph}>
        O Pomity não coleta, armazena, comercializa ou compartilha qualquer dado sensível do usuário. O aplicativo foi projetado para operar localmente no seu dispositivo sem a necessidade de acessar informações pessoais ou sensíveis.
      </p>

      <p style={styles.paragraph}><strong>3. Uso de Informações</strong></p>
      <p style={styles.paragraph}>
        Como o Pomity não coleta dados sensíveis ou pessoais, não utilizamos, processamos ou compartilhamos qualquer informação sobre você. Toda a funcionalidade do aplicativo ocorre de maneira offline e local, garantindo total privacidade e segurança dos seus dados.
      </p>

      <p style={styles.paragraph}><strong>4. Permissões do Aplicativo</strong></p>
      <p style={styles.paragraph}>
        O Pomity pode solicitar permissões básicas para operar corretamente no seu dispositivo, como acesso ao sistema de notificações ou ao cronômetro. Essas permissões são usadas exclusivamente para fornecer a funcionalidade principal do aplicativo e não para coletar dados.
      </p>

      <p style={styles.paragraph}><strong>5. Compartilhamento de Informações</strong></p>
      <p style={styles.paragraph}>
        Como não coletamos dados, também não há compartilhamento de informações com terceiros. O Pomity é um aplicativo completamente seguro e privado, focado em fornecer uma experiência sem a necessidade de acessar ou compartilhar seus dados pessoais.
      </p>

      <p style={styles.paragraph}><strong>6. Segurança</strong></p>
      <p style={styles.paragraph}>
        Embora o Pomity não processe ou armazene informações pessoais, nos comprometemos a aplicar as melhores práticas de segurança para garantir que o aplicativo funcione de forma confiável e sem vulnerabilidades que possam comprometer a sua privacidade.
      </p>

      <p style={styles.paragraph}><strong>7. Atualizações</strong></p>
      <p style={styles.paragraph}>
        Podemos atualizar esta política periodicamente para refletir mudanças nas práticas do aplicativo ou em resposta a novas exigências legais. Quando fizermos isso, a data da última atualização será revisada e qualquer mudança significativa será comunicada através da loja de aplicativos.
      </p>

      <p style={styles.paragraph}><strong>8. Contato</strong></p>
      <p style={styles.paragraph}>
        Se você tiver qualquer dúvida sobre esta política de privacidade ou sobre o uso do Pomity, por favor, entre em contato conosco através do suporte disponível na loja de aplicativos.
      </p>

      <p style={styles.paragraph}><strong>9. Conformidade com a loja de aplicativos</strong></p>
      <p style={styles.paragraph}>
        O Pomity está em conformidade com todas as diretrizes da loja de aplicativos, garantindo que o aplicativo respeita e protege os direitos dos usuários, conforme as exigências da plataforma.
      </p>
    </div>
  );
}

const styles = {
  container: {
    textAlign: 'justify',
    margin: '20px',
    textIndent: '30px',
    maxWidth: '100%', // Responsivo
    boxSizing: 'border-box',
  },
  title: {
    fontSize: '2rem',
    textAlign: 'center',
  },
  paragraph: {
    marginBottom: '1rem',
    lineHeight: '1.6',
    fontSize: '1rem',
    '@media (max-width: 768px)': {
      fontSize: '0.9rem',
      textIndent: '15px',
    },
  },
};

export default PomityPolicy;
