import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from '../App';
import Pomity from './Pomity';
import Blockity from './Blockity';
import Terapity from './Terapity';
import PomityPolicy from './PomityPolicy';
import BlockityPolicy from './BlockityPolicy';
import TerapityPolicy from './TerapityPolicy';
import Potity from './Potity';
import PotityPolicy from './PotityPolicy';
import Portrity from './Portrity';
import PortrityPolicy from './PortrityPolicy';
import Consity from './Consity';
import ConsityPolicy from './ConsityPolicy';
import Wifity from './Wifity';  // Adicionando Wifity
import WifityPolicy from './WifityPolicy';  // Adicionando WifityPolicy

function MainRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/pomity" element={<Pomity />} />
        <Route path="/blockity" element={<Blockity />} />
        <Route path="/terapity" element={<Terapity />} />
        <Route path="/pomity/policy" element={<PomityPolicy />} />
        <Route path="/blockity/policy" element={<BlockityPolicy />} />
        <Route path="/terapity/policy" element={<TerapityPolicy />} />
        <Route path="/potity" element={<Potity />} />
        <Route path="/potity/policy" element={<PotityPolicy />} />
        <Route path="/portrity" element={<Portrity />} />
        <Route path="/portrity/policy" element={<PortrityPolicy />} />
        <Route path="/consity" element={<Consity />} />
        <Route path="/consity/policy" element={<ConsityPolicy />} />
        <Route path="/wifity" element={<Wifity />} />  {/* Adicionando Rota Wifity */}
        <Route path="/wifity/policy" element={<WifityPolicy />} />  {/* Adicionando Rota WifityPolicy */}
      </Routes>
    </Router>
  );
}

export default MainRouter;
