import React from 'react';

function PotityPolicy() {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Política de Privacidade do Potity</h1>
      
      <p style={styles.paragraph}><strong>1. Introdução</strong></p>
      <p style={styles.paragraph}>
        Bem-vindo ao Potity! Este aplicativo foi desenvolvido com base no conceito de honey pot para proteger sistemas e informações de ameaças indesejadas. Estamos comprometidos em proteger a sua privacidade e garantir que seus dados sejam tratados de forma segura e responsável.
      </p>

      <p style={styles.paragraph}><strong>2. Coleta de Informações</strong></p>
      <p style={styles.paragraph}>
        O Potity solicita ao usuário que conceda permissões específicas para acessar determinadas informações. O fornecimento dessas permissões é totalmente opcional e cabe ao usuário decidir se deseja concedê-las. As informações coletadas são utilizadas exclusivamente para cruzar dados e identificar possíveis ameaças.
      </p>

      <p style={styles.paragraph}><strong>3. Uso de Informações</strong></p>
      <p style={styles.paragraph}>
        As informações obtidas através das permissões concedidas são utilizadas para melhorar a eficácia do Potity em identificar e mitigar ameaças. O Potity não utiliza os dados de forma indevida e respeita todas as normas de privacidade e segurança.
      </p>

      <p style={styles.paragraph}><strong>4. Permissões do Aplicativo</strong></p>
      <p style={styles.paragraph}>
        O Potity pode solicitar permissões para acessar informações como localização, rede e outros dados relevantes. Essas permissões são usadas exclusivamente para a funcionalidade central do aplicativo, e o usuário tem total controle sobre a concessão ou não dessas permissões.
      </p>

      <p style={styles.paragraph}><strong>5. Compartilhamento de Informações</strong></p>
      <p style={styles.paragraph}>
        O Potity tem como propósito específico de proteção contra ameaças. Todas as operações do aplicativo ocorrem de forma segura e dentro dos limites estabelecidos pelo usuário.
      </p>

      <p style={styles.paragraph}><strong>6. Segurança</strong></p>
      <p style={styles.paragraph}>
        Embora o Potity utilize informações sensíveis, aplicamos as melhores práticas de segurança para garantir que esses dados sejam tratados com o máximo de cuidado e proteção contra acessos não autorizados.
      </p>

      <p style={styles.paragraph}><strong>7. Atualizações</strong></p>
      <p style={styles.paragraph}>
        Esta política de privacidade pode ser atualizada periodicamente para refletir mudanças nas práticas do aplicativo ou em resposta a novas exigências legais. A data da última atualização será revisada e qualquer mudança significativa será comunicada através da loja de aplicativos.
      </p>

      <p style={styles.paragraph}><strong>8. Contato</strong></p>
      <p style={styles.paragraph}>
        Se você tiver qualquer dúvida sobre esta política de privacidade ou sobre o uso do Potity, entre em contato conosco através do suporte disponível na loja de aplicativos.
      </p>

      <p style={styles.paragraph}><strong>9. Conformidade com a loja de aplicativos</strong></p>
      <p style={styles.paragraph}>
        O Potity está em conformidade com todas as diretrizes da loja de aplicativos, garantindo que o aplicativo respeita e protege os direitos dos usuários, conforme as exigências da plataforma.
      </p>
    </div>
  );
}

const styles = {
  container: {
    textAlign: 'justify',
    margin: '20px',
    textIndent: '30px',
    maxWidth: '100%', // Responsivo
    boxSizing: 'border-box',
  },
  title: {
    fontSize: '2rem',
    textAlign: 'center',
  },
  paragraph: {
    marginBottom: '1rem',
    lineHeight: '1.6',
    fontSize: '1rem',
    '@media (max-width: 768px)': {
      fontSize: '0.9rem',
      textIndent: '15px',
    },
  },
};

export default PotityPolicy;
