import React from 'react';
import './Navbar.css';

function Navbar() {
  return (
    <nav className="Navbar">
      <a href="#sobre">Sobre</a>
      <a href="#tecnologia">Tecnologia</a>
      <a href="#modelos">Modelos</a>
      <a href="#contato">Contato</a>
    </nav>
  );
}

export default Navbar;
