import React from 'react';

function TerapityPolicy() {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Política de Privacidade do Terapity</h1>
      
      <p style={styles.paragraph}><strong>1. Introdução</strong></p>
      <p style={styles.paragraph}>
        Bem-vindo ao Terapity! Este aplicativo foi desenvolvido para oferecer atividades terapêuticas e de distração personalizadas, com o objetivo de aumentar o engajamento e a eficácia das terapias. Estamos comprometidos em proteger a sua privacidade e garantir que seus dados sejam tratados de forma segura e responsável.
      </p>

      <p style={styles.paragraph}><strong>2. Coleta de Informações</strong></p>
      <p style={styles.paragraph}>
        O Terapity solicita acesso à câmera e à galeria do seu dispositivo. Essas permissões são necessárias para que o usuário possa capturar e utilizar imagens nas atividades personalizadas oferecidas pelo aplicativo. O Terapity não armazena, não divulga e não comercializa nenhum dado do usuário.
      </p>

      <p style={styles.paragraph}><strong>3. Uso de Informações</strong></p>
      <p style={styles.paragraph}>
        As informações obtidas através das permissões concedidas são utilizadas exclusivamente para melhorar a experiência do usuário com as atividades terapêuticas e de distração. O Terapity não utiliza os dados de forma indevida e não compartilha informações com terceiros.
      </p>

      <p style={styles.paragraph}><strong>4. Permissões do Aplicativo</strong></p>
      <p style={styles.paragraph}>
        O Terapity pode solicitar permissões para acessar a câmera e a galeria do dispositivo. Essas permissões são utilizadas exclusivamente para a funcionalidade central do aplicativo, e o usuário tem total controle sobre a concessão dessas permissões.
      </p>

      <p style={styles.paragraph}><strong>5. Compartilhamento de Informações</strong></p>
      <p style={styles.paragraph}>
        O Terapity não compartilha, comercializa ou divulga as informações coletadas. Todas as operações do aplicativo ocorrem de forma privada e segura, dentro do dispositivo do usuário.
      </p>

      <p style={styles.paragraph}><strong>6. Segurança</strong></p>
      <p style={styles.paragraph}>
        Embora o Terapity utilize informações sensíveis, aplicamos as melhores práticas de segurança para garantir que esses dados sejam tratados com o máximo de cuidado e proteção contra acessos não autorizados.
      </p>

      <p style={styles.paragraph}><strong>7. Atualizações</strong></p>
      <p style={styles.paragraph}>
        Esta política de privacidade pode ser atualizada periodicamente para refletir mudanças nas práticas do aplicativo ou em resposta a novas exigências legais. A data da última atualização será revisada e qualquer mudança significativa será comunicada através da loja de aplicativos.
      </p>

      <p style={styles.paragraph}><strong>8. Contato</strong></p>
      <p style={styles.paragraph}>
        Se você tiver qualquer dúvida sobre esta política de privacidade ou sobre o uso do Terapity, entre em contato conosco através do suporte disponível na loja de aplicativos.
      </p>

      <p style={styles.paragraph}><strong>9. Conformidade com a loja de aplicativos</strong></p>
      <p style={styles.paragraph}>
        O Terapity está em conformidade com todas as diretrizes da loja de aplicativos, garantindo que o aplicativo respeita e protege os direitos dos usuários, conforme as exigências da plataforma.
      </p>
    </div>
  );
}

const styles = {
  container: {
    textAlign: 'justify',
    margin: '20px',
    textIndent: '30px',
    maxWidth: '100%', // Responsivo
    boxSizing: 'border-box',
  },
  title: {
    fontSize: '2rem',
    textAlign: 'center',
  },
  paragraph: {
    marginBottom: '1rem',
    lineHeight: '1.6',
    fontSize: '1rem',
    '@media (max-width: 768px)': {
      fontSize: '0.9rem',
      textIndent: '15px',
    },
  },
};

export default TerapityPolicy;
