import React from 'react';

function ConsityPolicy() {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Política de Privacidade do Consity</h1>
      
      <p style={styles.paragraph}><strong>1. Introdução</strong></p>
      <p style={styles.paragraph}>
        Bem-vindo ao Consity! Este aplicativo foi desenvolvido para atender as necessidades administrativas de profissionais e estabelecimentos, proporcionando uma gestão mais eficiente. Estamos comprometidos em proteger a sua privacidade e garantir que os dados sejam tratados de forma segura e responsável.
      </p>

      <p style={styles.paragraph}><strong>2. Coleta de Informações</strong></p>
      <p style={styles.paragraph}>
        O Consity não solicita, não armazena, não divulga e não comercializa nenhum dado do usuário ou das instituições. O aplicativo foi projetado para funcionar sem a necessidade de acessar informações pessoais ou institucionais, garantindo total privacidade e segurança.
      </p>

      <p style={styles.paragraph}><strong>3. Uso de Informações</strong></p>
      <p style={styles.paragraph}>
        Como o Consity não coleta dados dos usuários ou das instituições, não há qualquer uso, processamento ou compartilhamento de informações pessoais ou institucionais. Todo o funcionamento do aplicativo ocorre localmente no dispositivo, sem interação com servidores externos.
      </p>

      <p style={styles.paragraph}><strong>4. Permissões do Aplicativo</strong></p>
      <p style={styles.paragraph}>
        O Consity não solicita permissões além das necessárias para o correto funcionamento administrativo. Essas permissões são usadas exclusivamente para proporcionar a funcionalidade principal do aplicativo e não envolvem a coleta de dados.
      </p>

      <p style={styles.paragraph}><strong>5. Compartilhamento de Informações</strong></p>
      <p style={styles.paragraph}>
        O Consity não compartilha, comercializa ou divulga quaisquer informações, pois não realiza coleta de dados. Todas as operações do aplicativo ocorrem de forma privada e segura, dentro do dispositivo do usuário ou da instituição.
      </p>

      <p style={styles.paragraph}><strong>6. Segurança</strong></p>
      <p style={styles.paragraph}>
        Embora o Consity não processe ou armazene informações pessoais ou institucionais, aplicamos as melhores práticas de segurança para garantir que o aplicativo funcione de forma confiável e sem vulnerabilidades que possam comprometer a experiência do usuário.
      </p>

      <p style={styles.paragraph}><strong>7. Atualizações</strong></p>
      <p style={styles.paragraph}>
        Esta política de privacidade pode ser atualizada periodicamente para refletir mudanças nas práticas do aplicativo ou em resposta a novas exigências legais. A data da última atualização será revisada e qualquer mudança significativa será comunicada através da loja de aplicativos.
      </p>

      <p style={styles.paragraph}><strong>8. Contato</strong></p>
      <p style={styles.paragraph}>
        Se você tiver qualquer dúvida sobre esta política de privacidade ou sobre o uso do Consity, entre em contato conosco através do suporte disponível na loja de aplicativos.
      </p>

      <p style={styles.paragraph}><strong>9. Conformidade com a loja de aplicativos</strong></p>
      <p style={styles.paragraph}>
        O Consity está em conformidade com todas as diretrizes da loja de aplicativos, garantindo que o aplicativo respeita e protege os direitos dos usuários e das instituições, conforme as exigências da plataforma.
      </p>
    </div>
  );
}

const styles = {
  container: {
    textAlign: 'justify',
    margin: '20px',
    textIndent: '30px',
    maxWidth: '100%', // Responsivo
    boxSizing: 'border-box',
  },
  title: {
    fontSize: '2rem',
    textAlign: 'center',
  },
  paragraph: {
    marginBottom: '1rem',
    lineHeight: '1.6',
    fontSize: '1rem',
    '@media (max-width: 768px)': {
      fontSize: '0.9rem',
      textIndent: '15px',
    },
  },
};

export default ConsityPolicy;
